const eleanorFavouriteDesigners = [
  {
    slug: "a-f-vandevorst",
  },
  {
    slug: "acne-studios",
  },
  {
    slug: "agnes-b-",
  },
  {
    slug: "azzedine-alaia",
  },
  {
    slug: "azzedine-alaia",
  },
  {
    slug: "alberta-ferretti",
  },
  {
    slug: "alexander-mcqueen",
  },
  {
    slug: "alexander-mcqueen",
  },
  {
    slug: "alexander-mcqueen",
  },
  {
    slug: "alexander-wang",
  },
  {
    slug: "altuzarra",
  },
  {
    slug: "and-re-walker",
  },
  {
    slug: "ann-demeulemeester",
  },
  {
    slug: "ann-demeulemeester",
  },
  {
    slug: "anna-sui",
  },
  {
    slug: "anne-valerie-hash",
  },
  {
    slug: "anthony-vaccarello",
  },
  {
    slug: "armani-prive",
  },
  {
    slug: "atelier-versace",
  },
  {
    slug: "balenciaga",
  },
  {
    slug: "balenciaga",
  },
  {
    slug: "balenciaga",
  },
  {
    slug: "balenciaga",
  },
  {
    slug: "balmain",
  },
  {
    slug: "barragan",
  },
  {
    slug: "batsheva",
  },
  {
    slug: "behnaz-sarafpour",
  },
  {
    slug: "betsey-johnson",
  },
  {
    slug: "blumarine",
  },
  {
    slug: "bode",
  },
  {
    slug: "hugo-boss",
  },
  {
    slug: "bottega-veneta",
  },
  {
    slug: "bouchra-jarrar",
  },
  {
    slug: "brock-collection",
  },
  {
    slug: "burberry-prorsum",
  },
  {
    slug: "cacharel",
  },
  {
    slug: "calvin-klein",
  },
  {
    slug: "calvin-klein-collection",
  },
  {
    slug: "carolina-herrera",
  },
  {
    slug: "cdlm",
  },
  {
    slug: "celine",
  },
  {
    slug: "celine",
  },
  {
    slug: "celine",
  },
  {
    slug: "central-saint-martins",
  },
  {
    slug: "cerruti",
  },
  {
    slug: "chalayan",
  },
  {
    slug: "chanel",
  },
  {
    slug: "chanel",
  },
  {
    slug: "chanel",
  },
  {
    slug: "charles-jeffrey-loverboy",
  },
  {
    slug: "chloe",
  },
  {
    slug: "chloe",
  },
  {
    slug: "christian-dior",
  },
  {
    slug: "christian-dior",
  },
  {
    slug: "christian-dior",
  },
  {
    slug: "christian-dior",
  },
  {
    slug: "christian-lacroix",
  },
  {
    slug: "christian-lacroix",
  },
  {
    slug: "christopher-john-rogers",
  },
  {
    slug: "christopher-kane",
  },
  {
    slug: "christopher-kane",
  },
  {
    slug: "collina-strada",
  },
  {
    slug: "comme-des-garcons",
  },
  {
    slug: "comme-des-garcons",
  },
  {
    slug: "comme-des-garcons",
  },
  {
    slug: "comme-des-garcons",
  },
  {
    slug: "craig-green",
  },
  {
    slug: "delpozo",
  },
  {
    slug: "derek-lam",
  },
  {
    slug: "diane-von-furstenberg",
  },
  {
    slug: "diesel",
  },
  {
    slug: "dion-lee",
  },
  {
    slug: "dior-homme",
  },
  {
    slug: "dirk-bikkembergs",
  },
  {
    slug: "dolce-gabbana",
  },
  {
    slug: "donna-karan",
  },
  {
    slug: "dries-van-noten",
  },
  {
    slug: "dries-van-noten",
  },
  {
    slug: "dries-van-noten",
  },
  {
    slug: "dsquared",
  },
  {
    slug: "eckhaus-latta",
  },
  {
    slug: "eckhaus-latta",
  },
  {
    slug: "edeline-lee",
  },
  {
    slug: "emanuel-ungaro",
  },
  {
    slug: "emilia-wickstead",
  },
  {
    slug: "emilio-pucci",
  },
  {
    slug: "emporio-armani",
  },
  {
    slug: "erdem",
  },
  {
    slug: "escada",
  },
  {
    slug: "etro",
  },
  {
    slug: "faith-connexion",
  },
  {
    slug: "fashion-east",
  },
  {
    slug: "fendi",
  },
  {
    slug: "francesco-scognamiglio",
  },
  {
    slug: "gareth-pugh",
  },
  {
    slug: "giles-deacon",
  },
  {
    slug: "giorgio-armani",
  },
  {
    slug: "givenchy",
  },
  {
    slug: "givenchy",
  },
  {
    slug: "gmbh",
  },
  {
    slug: "gosha-rubchinskiy",
  },
  {
    slug: "gucci",
  },
  {
    slug: "gucci",
  },
  {
    slug: "gypsy-sport",
  },
  {
    slug: "haider-ackermann",
  },
  {
    slug: "haider-ackermann",
  },
  {
    slug: "hermes",
  },
  {
    slug: "heron-preston",
  },
  {
    slug: "herve-leger",
  },
  {
    slug: "hood-by-air",
  },
  {
    slug: "imitation-of-christ",
  },
  {
    slug: "imitation-of-christ",
  },
  {
    slug: "imitation-of-christ",
  },
  {
    slug: "iris-van-herpen",
  },
  {
    slug: "isaac-mizrahi",
  },
  {
    slug: "isabel-marant",
  },
  {
    slug: "issey-miyake",
  },
  {
    slug: "issey-miyake",
  },
  {
    slug: "issey-miyake",
  },
  {
    slug: "jacquemus",
  },
  {
    slug: "jean-paul-gaultier",
  },
  {
    slug: "jean-paul-gaultier",
  },
  {
    slug: "jean-paul-gaultier",
  },
  {
    slug: "jeremy-scott",
  },
  {
    slug: "jil-sander",
  },
  {
    slug: "jil-sander",
  },
  {
    slug: "jil-sander",
  },
  {
    slug: "john-galliano",
  },
  {
    slug: "john-galliano",
  },
  {
    slug: "john-galliano",
  },
  {
    slug: "jonathan-saunders",
  },
  {
    slug: "juicy-couture",
  },
  {
    slug: "junya-watanabe",
  },
  {
    slug: "junya-watanabe",
  },
  {
    slug: "junya-watanabe",
  },
  {
    slug: "j-w-anderson",
  },
  {
    slug: "j-w-anderson",
  },
  {
    slug: "kanye-west",
  },
  {
    slug: "karen-walker",
  },
  {
    slug: "karl-lagerfeld",
  },
  {
    slug: "kate-sylvester",
  },
  {
    slug: "kenzo",
  },
  {
    slug: "l-wren-scott",
  },
  {
    slug: "lanvin",
  },
  {
    slug: "lanvin",
  },
  {
    slug: "christophe-lemaire",
  },
  {
    slug: "christophe-lemaire",
  },
  {
    slug: "christophe-lemaire",
  },
  {
    slug: "loewe",
  },
  {
    slug: "loewe",
  },
  {
    slug: "loewe",
  },
  {
    slug: "louis-vuitton",
  },
  {
    slug: "louis-vuitton",
  },
  {
    slug: "louis-vuitton",
  },
  {
    slug: "luella",
  },
  {
    slug: "maison-martin-margiela",
  },
  {
    slug: "maison-martin-margiela",
  },
  {
    slug: "maison-martin-margiela",
  },
  {
    slug: "maison-martin-margiela",
  },
  {
    slug: "marc-by-marc-jacobs",
  },
  {
    slug: "marc-jacobs",
  },
  {
    slug: "marc-jacobs",
  },
  {
    slug: "marc-jacobs",
  },
  {
    slug: "margaret-howell",
  },
  {
    slug: "marine-serre",
  },
  {
    slug: "mark-fast",
  },
  {
    slug: "marni",
  },
  {
    slug: "marni",
  },
  {
    slug: "marques-almeida",
  },
  {
    slug: "martin-grant",
  },
  {
    slug: "martine-rose",
  },
  {
    slug: "mary-katrantzou",
  },
  {
    slug: "maryam-nassir-zadeh",
  },
  {
    slug: "matthew-williamson",
  },
  {
    slug: "matty-bovan",
  },
  {
    slug: "meadham-kirchhoff",
  },
  {
    slug: "miguel-adrover",
  },
  {
    slug: "molly-goddard",
  },
  {
    slug: "moschino",
  },
  {
    slug: "moschino-cheap-chic",
  },
  {
    slug: "mugler",
  },
  {
    slug: "n-hoolywood",
  },
  {
    slug: "narciso-rodriguez",
  },
  {
    slug: "no-21",
  },
  {
    slug: "off-white",
  },
  {
    slug: "olympia-le-tan",
  },
  {
    slug: "opening-ceremony",
  },
  {
    slug: "oscar-de-la-renta",
  },
  {
    slug: "ottolinger",
  },
  {
    slug: "our-legacy",
  },
  {
    slug: "palomo-spain",
  },
  {
    slug: "perry-ellis",
  },
  {
    slug: "peter-pilotto",
  },
  {
    slug: "philip-treacy",
  },
  {
    slug: "pierre-balmain",
  },
  {
    slug: "prabal-gurung",
  },
  {
    slug: "prada",
  },
  {
    slug: "prada",
  },
  {
    slug: "prada",
  },
  {
    slug: "prada",
  },
  {
    slug: "pringle-of-scotland",
  },
  {
    slug: "priscavera",
  },
  {
    slug: "proenza-schouler",
  },
  {
    slug: "proenza-schouler",
  },
  {
    slug: "protagonist",
  },
  {
    slug: "public-school",
  },
  {
    slug: "pyer-moss",
  },
  {
    slug: "paco-rabanne",
  },
  {
    slug: "rachel-comey",
  },
  {
    slug: "rachel-zoe",
  },
  {
    slug: "raf-simons",
  },
  {
    slug: "raf-simons",
  },
  {
    slug: "ralph-lauren",
  },
  {
    slug: "reed-krakoff",
  },
  {
    slug: "riccardo-tisci",
  },
  {
    slug: "rick-owens",
  },
  {
    slug: "roberto-cavalli",
  },
  {
    slug: "rochas",
  },
  {
    slug: "rodarte",
  },
  {
    slug: "rodarte",
  },
  {
    slug: "saint-laurent",
  },
  {
    slug: "sandy-liang",
  },
  {
    slug: "sc103",
  },
  {
    slug: "schiaparelli",
  },
  {
    slug: "sharon-wauchob",
  },
  {
    slug: "sies-marjan",
  },
  {
    slug: "sonia-rykiel",
  },

  {
    slug: "stella-mccartney",
  },
  {
    slug: "telfar",
  },
  {
    slug: "row",
  },
  {
    slug: "row",
  },
  {
    slug: "theyskens-theory",
  },
  {
    slug: "theyskens-theory",
  },
  {
    slug: "thom-browne",
  },
  {
    slug: "thom-browne",
  },
  {
    slug: "tom-ford",
  },
  {
    slug: "tom-ford",
  },
  {
    slug: "tommy-hilfiger",
  },
  {
    slug: "tsumori-chisato",
  },
  {
    slug: "undercover",
  },
  {
    slug: "ungaro-couture",
  },
  {
    slug: "valentino",
  },
  {
    slug: "vanessa-bruno",
  },
  {
    slug: "vaquera",
  },
  {
    slug: "vaquera",
  },
  {
    slug: "vaquera",
  },
  {
    slug: "vejas",
  },
  {
    slug: "vera-wang",
  },
  {
    slug: "versace",
  },
  {
    slug: "vetements",
  },
  {
    slug: "vetements",
  },
  {
    slug: "vetements",
  },
  {
    slug: "vfiles",
  },
  {
    slug: "victoria-beckham",
  },
  {
    slug: "viktor-rolf",
  },
  {
    slug: "vionnet",
  },
  {
    slug: "vivienne-westwood",
  },
  {
    slug: "vtmnts",
  },
  {
    slug: "walesbonner",
  },
  {
    slug: "wales-bonner",
  },
  {
    slug: "walter-van-beirendonck",
  },
  {
    slug: "wes-gordon",
  },
  {
    slug: "y-project",
  },
  {
    slug: "yohji-yamamoto",
  },
  {
    slug: "zac-posen",
  },
  {
    slug: "zambesi",
  },
]

export function getDesignersArray(): string[] {
  return eleanorFavouriteDesigners.map((designer) => {
    return designer.slug
  })
}
